#userform {
    display: flex;
    flex-flow: row wrap;
}
 
#userform fieldset {
    flex: 1;
    border: 0px;
    margin: 0.5em;
}
 
#userform fieldset legend {
    padding: 5px 20px;
    border: 0px;
    font-weight: bold;
}
 
#userform fieldset.identiteit {
    flex: 2 100%;
}
 
#userform fieldset.identiteit legend {
    background: #009844;
}
 
#userform fieldset.account legend {
    background: #009844;
}
 
#userform fieldset.adres legend {
    background: #009844;
}
 
#userform div.DTE_Field {
    padding: 5px;
}
 
html.dark #userform fieldset.identiteit legend {
    background: #009844;
}
 
html.dark #userform fieldset.account legend {
    background: #009844;
}
 
html.dark #userform fieldset.adres legend {
    background: #009844;
}
